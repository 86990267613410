<template>
  <div-wrapper class="tracking-cnt">
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn goback" @click="goBack">
          <back-icon />
        </span>
        <h1>{{ $t("_returnOrdersTracking") }}</h1>
      </div>
      <div-wrapper class="rgt-iconbox">
        <span @click="showHelpModal = true" class="icon-Btn help-btn">
          <help-icon />
        </span>
      </div-wrapper>
    </header>
    <section class="mid-cnt">
      <div-wrapper class="filter-srch" v-if="lots.length">
        <Input
          iType="text"
          value
          name="search"
          :placeholder="$t('_searchHere')"
          class="input-srch"
          @input="getSearchInput"
        />
        <search-icon />
      </div-wrapper>
      <ul class="track-list" v-if="lots.length">
        <!-- remove className disable-track and add active-track for final state of lot -->
        <li v-for="(lot, index) in filteredList" :key="index">
          <div-wrapper class="lot-block">
            <div-wrapper class="info-lot">
              <h2>
                {{ $t("_lotId") }}: {{ lot.lot_id }}({{ lot.tracking_number }})
                <span v-if="lot.lotState === 'MANIFEST_CANCELLED'">Cancelled</span>
              </h2>
              <span class="text-date"
                >{{ $t("_date") }}: {{ lot.manifest_date }}</span
              >
            </div-wrapper>
            <div-wrapper
              class="order-item"
              @handleDivClick="goToOrderPage(lot.lot_id)"
            >
              <span>{{ $t("_viewOrders") }}</span>
            </div-wrapper>
          </div-wrapper>
          <div-wrapper class="track-block">
            <!-- add 'active-track' className according to condition to show active state in ui -->
            <div-wrapper
              :className="[
                (lot.lotState === 'MANIFEST_DISPATCHED' ||
                  lot.lotState === 'MANIFEST_DELIVERED') &&
                  'active-track',
                'track-col'
              ]"
            >
              <span class="icon-Btn">
                <courier-cart-icon />
              </span>
              <span class="text-title">C91</span>
            </div-wrapper>
            <!--  remove disable track className and add 'active-track' to show current state of lot-->
            <div-wrapper
              :className="[
                'track-col',
                lot.lotState === 'MANIFEST_DISPATCHED' ||
                lot.lotState === 'MANIFEST_DELIVERED'
                  ? 'active-track'
                  : 'disable-track',
                'track-line'
              ]"
            >
              <div-wrapper class="trackbar">
                <span class="break-point"></span>
                <!-- add style set width 50%(style="width:50%") to show movement of lot/orders in ui
                and add set width 100% for final state-->
                <div-wrapper
                  class="barline"
                  :style="{
                    width:
                      lot.lotState === 'MANIFEST_DISPATCHED'
                        ? '50%'
                        : lot.lotState === 'MANIFEST_DELIVERED'
                        ? '100%'
                        : '0%'
                  }"
                >
                  <span class="track-icon">
                    <fast-delivery-icon />
                  </span>
                </div-wrapper>
              </div-wrapper>
              <span class="text-title">{{ $t("_courierPartner") }}</span>
            </div-wrapper>
            <div-wrapper
              :className="[
                'track-col',
                lot.lotState === 'MANIFEST_DELIVERED'
                  ? 'active-track'
                  : 'disable-track'
              ]"
            >
              <span class="icon-Btn warehouse-icon">
                <warehouse-icon />
              </span>
              <span class="text-title">{{ $t("_warehouse") }}</span>
            </div-wrapper>
          </div-wrapper>
        </li>
      </ul>
      <no-order-msg v-else :message="$t('_noLot')" />
    </section>
    <help-modal
      @closeModal="showHelpModal = false"
      :text="ordersTrackingHelpText"
      v-if="showHelpModal"
    ></help-modal>
    <Popup :message="message" @handleClick="message = ''" :color="color" />
    <Loader v-if="isLoading" />
  </div-wrapper>
</template>
<script>
import { BASE_API } from "../utils/constants";
import { GET } from "../utils/HttpStatusCodeConst";
import ApiRequest from "../services/ApiRequest";
import FastDeliveryIcon from "@/components/svgIcons/FastDeliveryIcon";
import CourierCartIcon from "@/components/svgIcons/CourierCartIcon";
import WarehouseIcon from "@/components/svgIcons/WarehouseIcon";
import NoOrderMsg from "@/components/order/NoOrderMsg";
import DivWrapper from "@/components/form/DivWrapper";
import SearchIcon from "@/components/svgIcons/SearchIcon";
import BackIcon from "@/components/svgIcons/BackIcon";
import Loader from "@/components/commonUI/LoaderWrapper";
import Input from "@/components/form/InputWrapper";
import Popup from "@/components/Popup";
import HelpModal from "@/components/modals/HelpModal";
import HelpIcon from "@/components/svgIcons/HelpIcon";

export default {
  components: {
    FastDeliveryIcon,
    CourierCartIcon,
    WarehouseIcon,
    DivWrapper,
    SearchIcon,
    NoOrderMsg,
    BackIcon,
    Loader,
    Input,
    Popup,
    HelpModal,
    HelpIcon
  },

  data() {
    return {
      isLoading: false,
      search: "",
      lots: [],
      message: "",
      color: "",
      showHelpModal: false,
      ordersTrackingHelpText: this.$t("_ordersTrackingHelpText")
    };
  },

  computed: {
    filteredList() {
      return this.lots.filter((lot) => {
        return String(lot.lot_id).includes(this.search);
      });
    }
  },

  created() {
    this.isLoading = !this.isLoading;
    ApiRequest(BASE_API, `/partner91/getlot/`, GET)
      .then((_data) => {
        this.isLoading = !this.isLoading;
        this.lots = _data;
        // console.log(this.lots);
      })
      .catch(() => {
        this.isLoading = !this.isLoading;

        this.$swal({ text: this.$t("_pleaseTryAgain"), icon: "error" });
      });
  },

  methods: {
    goBack() {
      this.$router.back(-1);
    },

    getSearchInput(_event) {
      this[_event.target.name] = _event.target.value;
    },

    goToOrderPage(_lotId) {
      this.$router.push(`/lot-orders/${_lotId}`);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_return-orders-tracking.scss"
</style>
